// Change website animations
const animation = {
  // make it false to switch off fade-up animation
  animate: true,
  // animation playing duration
  duration: 300,
  // if true, animation plays only once when element comes on screen
  once: false,
};
// Change your display name on tha landing display
const header = {
  name: "Skylar",
};
const background = {
  // Options: Snow or Particle
  type: "Particle",
};
// Write a para about yourself here
// To update your image, go to './styles/images.css'
const section2title = "Education";
const about = {
  schoolName: "Carleton University, 2021.",
  schoolDesc: "Bachelor of Sciences - Major in Computer Science.",
};
// Edit your skill and the percentage you know about it
// To Add a skill, copy any one below and paste it after the last comma
const skillsBar = [
];
// Edit your projects, its name, your skills used to make it, and the url.
// You can omit freely anything if you dont have it
// To Add a Project, copy any one below and paste it after the last comma and increment the id's project number
const section3Title = "Past Projects";
const projects = [
  {
    // Add image in './styles/images.css' in #project1
    id: "familiarSpiritsPic",
    img: "project2",
    name: "Familiar Spirits",
    role: "Mobile Developer (2020-2021)",
    desc: [
      "5-star rating on the App Store, created an app that allows people to get to know each other based on different tiers of friendship and personality theory.",
      "Full git integration, developed in an Agile workflow, uses Real-Time Database API for continuous dynamic updates. Efficient robust algorithms and data structures used on the back-end for optimal competitive performance.",
      "Throughout documentation for testing, integration, maintenance and deployment stages."
      ],
    skills: [
      {
        name: "Swift",
        faClass: "fab fa-swift"
      },
      {
        name: "Google Cloud",
        faClass: "fas fa-cloud"
      },
      {
        name: "Google Firebase",
        faClass: "fas fa-fire"
      },
    ],
    url: "https://apps.apple.com/bs/app/familiar-spirits/id1528863037",
  },
  {
    // Add image in './styles/images.css' in #project2
    id: "myAI",
    name: "Emotion Recognition: Artificial Intelligence",
    role: "Software Developer (2020-2020)",
    desc: [
      "Tensorflow/Keras based Convolutional Neural Network for emotion classification with table probabilities based on Artificial Intelligence confidence (0=Angry, 1=Disgust, 2=Fear, 3=Happy, 4=Sad, 5=Surprise, 6=Neutral)",
      "Fully featured facial detection based on haar cascade. Used pooling from 3 hidden layers with softmax activation function for higher accuracy rating."
      ],      
    skills: [
        {
          name: "Python",
          faClass: "fab fa-python"
        },
        {
          name: "Tensor Flow",
          faClass: "fas fa-smile-beam"
        },
        {
          name: "Keras",
          faClass: "fab fa-gratipay"
        },
      ],
    url: "",
  },
  {
    // Add image in './styles/images.css' in #project3
    id: "project4",
    name: "Online Portfolio: skylarbruce.dev (this website!)",
    role: "Full-Stack Developer (2021-now)",
    desc: [
      "Full JS Rendering, git integration, Virtual DOM, SSL encryption, ReactJS Front-End, Firebase Back-End. Dynamic design response with multibrowser compatibility and caching."
      ], 
    skills: [
      {
        name: "react",
        faClass: "fab fa-react"
      },
      {
        name: "Google Cloud",
        faClass: "fas fa-cloud"
      },
      {
        name: "Google Firebase",
        faClass: "fas fa-fire"
      },
    ],
    url: "https://skylarbruce.dev/",
  },
  {
    id: "project2",
    name: "Pipes Plumbing",
    role: "Head of Marketing / Social Media · Full-Stack Developer (2019-2020)",
    desc: [
      "Automated optimized scheduled posting for Pipes Plumbing. Compatible with Instagram API, Facebook API. Also developed the website pipesplumbing.ca, website featured fully dynamic responsive mobile first design.",
      "Used ultra light-weight SASS for faster loading time based on location since it was for a local business."
      ],
    skills: [
      {
        name: "Javascript",
        faClass: "fab fa-js-square"
      },
      {
        name: "Html 5",
        faClass: "fab fa-html5"
      },
      {
        name: "CSS 3",
        faClass: "fab fa-css3-alt"
      },
      {
        name: "Google Cloud",
        faClass: "fas fa-cloud"
      },
      {
        name: "Google Firebase",
        faClass: "fas fa-fire"
      },
    ],
    url: "https://pipesplumbing.ca/",
  },
  {
    id: "javaGames",
    name: "Two Java Based Game Servers",
    role: "Software Developer (2016-2018)",
    desc: [
      "Created and hosted 2 java game servers, combining to have around 1000 total players.",
      "Robust object-Oriented software design with My-SQL database. Back-end features high performance PHP server with secure connection with ability to run 24 hours 7 days a week.",
      "Large scale project that required robust efficient algorithms and memory management for consistent long term run-time."
      ],
    skills: [
      {
        name: "java",
        faClass: "fab fa-java"
      },
      {
        name: "PHP",
        faClass: "fab fa-php"
      },
      {
        name: "MySQL",
        faClass: "fas fa-database"
      },
    ],
    url: "",
  },
  {
    id: "project3",
    name: "Gateway Project Builders",
    role: "Full-Stack Developer (2021-Now)",
    desc: [
      "Full compatibility with Real-Time Database API. Full JS Rendering, Virtual DOM, SSL encryption, ReactJS front-End, Firebase back-End. Dynamic design response with multi-browser compatibility and caching. (pending release)."
      ],
    skills: [
      {
        name: "react",
        faClass: "fab fa-react"
      },
      {
        name: "Google Cloud",
        faClass: "fas fa-cloud"
      },
      {
        name: "Google Firebase",
        faClass: "fas fa-fire"
      },
    ],
    url: "",
  },
];
// Edit your Miscellaneous Activities, its name and the url.
// You can omit freely anything if you dont have it
// To Add a Activity, copy any one below and paste it after the last comma and increment the id's Miscellaneous number
const section4Title = "Miscellaneous";
const miscellaneous = [
  {
    // Add image in './styles/images.css' in #misc1
    id: "misc1",
    name: "Miscellaneous 1",
    url: "https://github.com/",
  },
  {
    // Add image in './styles/images.css' in #misc2
    id: "misc2",
    name: "Miscellaneous 2",
    url: "https://github.com/",
  },
  {
    // Add image in './styles/images.css' in #misc3
    id: "misc3",
    name: "Miscellaneous 3",
    url: "https://github.com/",
  },
];
// Contact form text, and Formspree link(to send a submit contact through their API as in contact.js)
// To get your own jotform link, go to https://formspree.io/
// If you hacve the link already, paste it in the contactUrl below
const section5Title = "Let's get in Touch!";
const contact = {
  pitch:
    "I try and get back to everyone as soon as possible. You can download my resume or find me on Linkedin or instagram below!",
  copyright: "Skylar Bruce",
  contactUrl: "https://formspree.io/f/mvodwpav",
};
// Paste your respective social media links. You can omit any if you dont have it
// Upload your resume in your drive, get the shaareable link and paste it in the resume section
const social = {
  github: "https://github.com/skylovescoffee",
  facebook: "https://facebook.com",
  linkedin: "https://www.linkedin.com/in/skylarbruce/",
  instagram: "https://www.instagram.com/theskylarbruce/",
  resume: "https://firebasestorage.googleapis.com/v0/b/portfolio-4d574.appspot.com/o/skylar_resume_2021.pdf?alt=media",
};
// Dont change anything here
export {
  animation,
  header,
  background,
  about,
  skillsBar,
  projects,
  miscellaneous,
  contact,
  social,
  section2title,
  section3Title,
  section4Title,
  section5Title,
};
