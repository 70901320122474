import React from 'react'
import Skills from '../layouts/Skills'
/*
const Project = ({id, img, name, desc, url, skills}) => {
    return (
        <div data-aos="fade-up" className=" col-12 col-lg-6 project-card">
            <a title={url} className="project-name" rel="noopener noreferrer" target="_blank" href={url}><h2>{name}</h2></a>
            <h3>{ desc }</h3>
            <div className="image-project">
                <div className={`img-pro ${img}`}>
                </div>
            </div>
            <div className="row d-flex justify-content-center skills">
                    {skills && skills.map((x) => 
                        <Skills faClass={x.faClass} label={x.name}/>
                    )}
                </div> 
            
        </div>
    )
}
*/
const Project = ({id, name, role, desc, url, skills}) => {
    return (
    

        <div data-aos="fade-up" className=" col-12 col-lg-6 project-card ">
            <a title={url} className="project-name" rel="noopener noreferrer" target="_blank" href={url}>
                <h2>{name}</h2>
            </a>
            <h3>{role}</h3>
            <ul>
            {
                desc && desc.map((x) =>
                <li>{x}</li>
                )
            }
            </ul>
            <div className="image-project">
                <div className={`img-pro ${id}`}></div>
            </div>
            <div className="row d-flex justify-content-center skills">
                    {skills && skills.map((x) => 
                        <Skills faClass={x.faClass} label={x.name}/>
                    )}
                </div> 
        </div>
    )
}

export default Project
